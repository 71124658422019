<template>
  <div class="payment">
    <v-subheader>Payment Information</v-subheader>
    <v-card class="mx-auto">
        <v-container v-if="payment">
            <template>
                <v-simple-table>
                    <template v-slot:default>
                    <tbody>
                        <tr><td class="font-weight-bold">Order ID</td><td>{{ payment.order_id }}</td></tr>
                        <tr><td class="font-weight-bold">Invoice Number</td><td>{{ payment.invoice_number }}</td></tr>
                        <tr><td class="font-weight-bold">Total Bill</td><td>Rp. {{ payment.total_price.toLocaleString('id-ID') }}</td></tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </template>
        </v-container>
    </v-card>

    <v-subheader>Transfer To</v-subheader>
    <v-card class="mx-auto">
        <v-container>
            <template>
                <v-simple-table>
                    <template v-slot:default>
                    <tbody>
                        <tr>
                            <td><img src="img/bca.png"></td>
                            <td>BCA KCP abc No. Rek 123</td>
                        </tr>
                        <tr>
                            <td><img src="img/mandiri.png"></td>
                            <td>BANK MANDIRI KCP xyz No. Rek 456</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </template>
        </v-container>
    </v-card>

    <v-card class="mx-auto mt-3">
    <v-container class="pa-6">
    <v-layout row wrap>
      <v-flex xs12 text-center>
      <v-btn color="success" @click="finish">
          Finish
      </v-btn>
      </v-flex>
    </v-layout>
    </v-container>
    </v-card>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    computed: {
      ...mapGetters({
        payment  : 'payment',
      }),
    },
    created(){
        if(this.payment==undefined){
            this.setAlert({
                status : true,
                text  : 'Pembayaran undefined',
                color  : 'warning',
            })
            this.$router.push('/')
        }
    },
    methods: {
        ...mapActions({
            setAlert      : 'alert/set',
        }),
        finish(){
            this.setAlert({
                status : true,
                text  : 'Transaksi Anda berhasil',
                color  : 'success',
            })
            this.$router.push('/')
        }
    },
  }
</script>