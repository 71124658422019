"use strict"
import Vue from 'vue'
const Helper = {
    install(Vue) {
        Vue.prototype.appName = process.env.VUE_APP_NAME
        Vue.prototype.getImage = function (image) {
            if (image != null && image.length > 0) {
                // return process.env.VUE_APP_IMAGE_URL + "/" + image
                return "https://cms.bookstore.rezateknologi.com/public/storage/" + image
            }
            return "/img/unavailable.png"
        }
    }
}

Vue.use(Helper)